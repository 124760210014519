import React, { useState, useRef } from "react";
import Select from "components/Common/Select/Select";
import { KeysNav, InnerNav } from "./Keys.styles";
import Panel from "components/Common/Panel/Panel";
import { GetListTable } from "components/Context/getListTable/getListTable";

import { GetUnitsDropDown } from "components/Context/getUnitsDropDown/getUnitsDropDown";
import { GetBuildingsDropDown } from "components/Context/getBuildingDropDown/getBuildingDropDown";

import { getKeyRows, getKeyHeader } from "hooks/Key/keysTableFunctions";
import InfoIcon from "@mui/icons-material/Info";

const minimumRooms = 3;

const Rooms = () => {
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState();
  const [department, setDepartment] = useState();
  const [room, setRoom] = useState("");
  const [searchResults, setSearchResults] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const optionChoice = useRef();
  const saveRoom = useRef("");
  const [unitByProxy, setUnitByProxy] = useState(true);
  const offset = useRef(0);
  const saveRooms = useRef(0);
  const saveBuildings = useRef(0);

  const handleCheckbox1Change = () => {
    setUnitByProxy(!unitByProxy);
  };

  const handleSetRoom = (e) => {
    const value = e.target.value.replace(/\//g, "%2F").replace(/\\/g, "%5C");
    saveRoom.current = value;

    setRoomValue();
  };

  const setRoomValue = (e) => {
    const wrkRoom = saveRoom.current;

    if ((wrkRoom?.length ?? 0) >= minimumRooms || building || department) {
      setRoom(wrkRoom);
    } else {
      setRoom("");
    }
  };

  const handleSelectDepartment = (e) => {
    optionChoice.current = null;
    setDepartment(e);
    setRoomValue();
  };

  const handleSelectBuilding = (e) => {
    setBuilding(e);
    setRoomValue();
  };

  function updateCount(offsets) {
    offset.current = offsets;
  }

  function filterKeys(data) {
    let filtered = 0;

    let buildings = data
      .filter(({ bldg, bldgName }) => {
        const result = bldgName !== null && bldg !== null;

        if (!result) filtered++;
        return result;
      })
      .map(({ bldg, bldgName }) => ({
        id: bldg,
        name: toTitleCase(bldgName) + " (" + bldg + ")",
      }))
      .sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

    let buildingList = [
      ...new Map(
        buildings.map((item) => {
          if (building && building === item["id"]) {
            optionChoice.current = item;
          }
          return [item["id"], item];
        })
      ).values(),
    ];

    if (buildingList.length !== 0 || (room?.length ?? 0) < minimumRooms) {
      saveRooms.current = buildings.length + filtered;
      saveBuildings.current = buildingList.length;
    }

    setBuildings(buildingList);
  }

  const updateSearchCount = (srch) => {
    if (!srch) {
      clearCount(); // reset counts when there is a zero count
    } else {
      if (saveRooms.current == 0) saveRooms.current = srch;
      if (saveBuildings.current == 0 || building) saveBuildings.current = 1;
    }

    setSearchResults(srch);
  };

  const clearCount = () => {
    offset.current = 0;
    saveRooms.current = 0;
    saveBuildings.current = 0;
  };

  const keyByProxyDescription =
    "Checking this box will exclude keys by unit proxy. These include keys that are assigned to rooms, buildings, and spaces assigned to your unit NOT the keys themselves. This presents a list of only keys assigned directly to your department. ";

  const handleInfoClick = () => {
    alert(keyByProxyDescription);
  };

  return (
    <div>
      <KeysNav>
        <InnerNav>
          <GetUnitsDropDown
            onSelectItem={handleSelectDepartment}
            addSeleted={true}
            keysFilter={true}
            building={building}
            room={room}
            unitId={unitByProxy ? "$DEFIN" : null}
          />
          {department ? (
            <Select
              label="Building"
              optionsheader="-- Select a Building --"
              options={buildings}
              onChange={(e) => handleSelectBuilding(e)}
              defaultValue={building}
              addedValue={optionChoice.current}
              foundValue={(e) => foundBuilding(e)}
            />
          ) : (
            <GetBuildingsDropDown
              onSelectItem={handleSelectBuilding}
              addSeleted={true}
              defaultValue={building}
              keysFilter={true}
              department={department}
              room={room}
              unitId={unitByProxy ? "$DEFIN" : null}
            />
          )}
          <div>
            <label id="searchByRoom" htmlFor="room">
              Search by Room
            </label>
            <br />
            <input
              id="room"
              label="Room"
              type="text"
              className="form-control"
              placeholder="Search..."
              style={{
                height: "2.3rem",
                border: "1px solid rgb(179, 179, 179)",
              }}
              onChange={handleSetRoom}
              aria-labelledby="searchByRoom"
            />
          </div>
          &nbsp;
          <div className="checkboxContainer">
            <label
              id="excludeKeysByUnitProxy"
              htmlFor="excludeKeysByUnitProxyCheckbox"
            >
              Exclude Keys by Unit Proxy :
            </label>
            <input
              id="excludeKeysByUnitProxyCheckbox"
              type="checkbox"
              className="checkbox"
              checked={unitByProxy}
              aria-checked={unitByProxy}
              aria-details=""
              onChange={handleCheckbox1Change}
              aria-labelledby="excludeKeysByUnitProxy"
            />
            <InfoIcon
              role="tooltip"
              style={{
                marginLeft: ".5rem",
                height: "1rem",
                width: "1rem",
                color: "var(--illiniDarkBlue)",
                cursor: "pointer",
              }}
              aria-label={keyByProxyDescription}
              onClick={() => {
                handleInfoClick();
              }}
            />
          </div>
        </InnerNav>
        <InnerNav>
          <div>
            <label id="searchBar" htmlFor="search">
              Search
            </label>
            <br />
            <input
              id="search"
              label="Search"
              type="text"
              className="form-control"
              placeholder="Search..."
              style={{
                height: "2.3rem",
                border: "1px solid rgb(179, 179, 179)",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              aria-labelledby="searchBar"
            />
          </div>
        </InnerNav>
        <InnerNav>
          <Panel
            label="Keys"
            data={(saveRooms.current ?? 0) - (offset.current ?? 0)}
            headers=""
          />

          <Panel
            label="Buildings"
            data={saveBuildings.current ?? 0}
            headers=""
          />

          <Panel label="Results" data={searchResults} headers="" />
        </InnerNav>
      </KeysNav>

      <div>
        <GetListTable
          department={department}
          room={
            (room?.length ?? 0) >= minimumRooms || department || building
              ? room
              : null
          }
          building={building}
          forceBulding={true}
          search={search}
          unitId={unitByProxy ? "$DEFIN" : null}
          updateCount={updateCount}
          updateBuildings={(data) => filterKeys(data)}
          updateSearchCount={(srch) => updateSearchCount(srch)}
          getHeader={getKeyHeader}
          getRows={getKeyRows}
          keysFilterDatabase={true}
          formatType="key"
        />
      </div>
    </div>
  );

  function toTitleCase(str) {
    if (str === null) {
      return null;
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};
export default Rooms;
