import styled from "styled-components";

export const KeysNav = styled.div`
  width: 1370px;
  justify-content: flex-start;
  position: relative;

  margin: 0 auto;

  @media (max-width: 1180px) {
    width: 100%;
  }
`;
export const InnerNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 1rem 0px;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
  label {
    font-weight: 700;
  }

  .checkbox {
    margin-left: 0.5rem;
  }

  .checkboxContainer {
    margin-top: 1rem;
  }
`;
